import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../store/ducks/dialog.duck";
import Dialog from "../UI/molecules/Dialog";
import DialogHeader from "./DialogHeader";
import { BodyRegular } from "../UI/atoms/fonts/Body";
import { PrimaryButton } from "../UI/atoms/buttons/PrimaryButton";
import { acceptAndSyncAllMovements } from "../../services/Movements";
import { refreshAllMovements } from "../../store/ducks/companyMovements.duck";
import { Center } from "../UI/atoms/StructuralLayout";
import { selectCurrentUser, updateCurrentUser } from "../../store/ducks/auth.duck";
import { useTranslation } from "react-i18next";

export function WhereaboutsModifiedDialog(props: Props) {
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation()

  const outlookSyncEnabled = useMemo(() => user?.enableOutlookSync, [user]);

  const acceptAndSync = async () => {
    setIsLoading(true);
    try {
        await acceptAndSyncAllMovements();
        dispatch(refreshAllMovements());
        dispatch(updateCurrentUser(true));
    } finally {
      setIsLoading(false);
      dispatch(closeDialog())
    }
  }

  useEffect(() => {
    if (!user || !user?.whereaboutsEdited) {
      dispatch(closeDialog());
    }
  }, [user, dispatch]);

  return (
    <Dialog isOpen={true} onClose={acceptAndSync} showLogo={true}>
      <Center>
        <img src="/assets/images/man-fishing.svg" alt="Man fishing" />
      </Center>
      <DialogHeader title="dialog-headers.whereabouts-have-been-edited" />
      {outlookSyncEnabled ?
        <BodyRegular style={{marginBottom: 32}}>{t('whereabouts-changed-dialog.accept-message')}</BodyRegular> :
        <>
          <BodyRegular style={{marginBottom: 8}}>{t('whereabouts-changed-dialog.accept-with-no-outlook-sync-1')}</BodyRegular>
          <BodyRegular style={{marginBottom: 32}}>{t('whereabouts-changed-dialog.accept-with-no-outlook-sync-2')}</BodyRegular>
        </>
      }

      <PrimaryButton click={acceptAndSync}
                     text={outlookSyncEnabled ? 'whereabouts-changed-dialog.accept-and-sync' : 'whereabouts-changed-dialog.accept'}
                     fullWidth={true}
                     style={{marginBottom: 16}}
                     disabled={isLoading} />
    </Dialog>
  )
}

interface Props {
}
