import React, { useMemo } from 'react';
import styled from "styled-components/macro";
import { MeetingRoomBooking, MeetingRoomStatus, RoomBookingSource } from "../models/MeetingRooms";
import { HOUR_TIME_SLOT_HEIGHT, MEETING_ROOM_HEADER_HEIGHT } from "./StructuralComponents";
import Colours from "../../../UI/atoms/Colours";
import { BodySmall, BodyXXSmall } from "../../../UI/atoms/fonts/Body";
import Icon, { IconTypes } from '../../../UI/atoms/icon/Icon';
import { useDispatch } from "react-redux";
import { DialogIdentifiers, openDialogWithPayload } from "../../../../store/ducks/dialog.duck";
import { useTranslation } from "react-i18next";
import moment from "moment";

export function MeetingRoomBookingContentCard(props: Props) {
  const dispatch = useDispatch();
  const {booking, excludeOffset} = props;
  const {t} = useTranslation();

  const onClick = () => {
    dispatch(openDialogWithPayload({
      payload: { booking: booking },
      activeDialog: DialogIdentifiers.EditBookMeetingRoom
    }));
  }

  const hourOffset = useMemo(() => {
    const offset = !!excludeOffset ? 0 : MEETING_ROOM_HEADER_HEIGHT;
    return moment(booking.startDateTime).local().hour() * HOUR_TIME_SLOT_HEIGHT + offset;
  }, [booking, excludeOffset]);

  const minuteOffset = useMemo(() => {

    return (moment(booking.startDateTime).local().minute() / 60) * HOUR_TIME_SLOT_HEIGHT;
  }, [booking]);

  const height = useMemo(() => {
    return (moment(booking.endDateTime).local().diff(moment(booking.startDateTime), 'minutes') / 60) * HOUR_TIME_SLOT_HEIGHT;
  }, [booking]);

  return (
      <Container hourOffset={hourOffset}
                 minuteOffset={minuteOffset}
                 bookingHeight={height}
                 bookingSource={booking.source}
                 onClick={onClick}
                 source={booking.source}
                 status={booking.status ?? MeetingRoomStatus.COMPLETE}>
        <ContentWrapper bookingHeight={height}>
          {booking.bookerName ? <>
            <BodySmall weight={600} className={"meeting__bookerName"} colour={Colours.white}>{booking.bookerName}</BodySmall>
            <BodyXXSmall weight={400} colour={Colours.white}>{booking?.name}</BodyXXSmall>
          </> : <>
            <BodySmall weight={600} style={{marginBottom: 4}} className={"meeting__bookerName"} colour={Colours.white}>Private</BodySmall>
            <BodyXXSmall weight={400} colour={Colours.white}>{t('room-booking-dialog.no-booker-name')}</BodyXXSmall>
          </>}
          <Icon className={'meetingIcon'} icon={IconTypes.Users} color={'white'} />
        </ContentWrapper>
        <DebugBox className={'meetingBooking__debugBox'}>
          {getDebugMessage(booking?.status ?? MeetingRoomStatus.COMPLETE, booking.source)}
        </DebugBox>
      </Container>
  )
}

interface Props {
  booking: MeetingRoomBooking;
  excludeOffset?: boolean;
}

function getOutlineColour(status: MeetingRoomStatus, source: RoomBookingSource) {
  if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
    if (status === MeetingRoomStatus.PENDING) {
      // Users booking that can be managed but not yet found in meeting room calendar
      return Colours.orange;
    }
    // In system and matched to outlook
    return Colours.green;
  }

  if (status === MeetingRoomStatus.PENDING) {
    // Room is linked to outlook. Booking has been made but has not yet been matched to an outlook booking.
    return Colours.plannerOrange;
  }

  if (status === MeetingRoomStatus.COMPLETE) {
    if (source === RoomBookingSource.TEAM_TODAY) {
      // Team today booking, room not linked to Outlook
      return Colours.blue;
    } else if (source === RoomBookingSource.OUTLOOK) {
      // Outlook booking, room linked to Outlook but no booking found in Team Today
      return Colours.outlookBlue;
    } else if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
      // In system and matched to outlook, but shouldn't get here
      return Colours.green;
    }
  }

  return Colours.darkGrey;
}

function getFillColour(status: MeetingRoomStatus, source: RoomBookingSource) {
  if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
    if (status === MeetingRoomStatus.PENDING) {
      // Users booking that can be managed but not yet found in meeting room calendar
      return Colours.orange;
    }
    // In system and matched to outlook
    return Colours.green;
  }

  if (status === MeetingRoomStatus.PENDING) {
    // Room is linked to outlook. Booking has been made but has not yet been matched to an outlook booking.
    return Colours.orange;
  }

  if (status === MeetingRoomStatus.COMPLETE) {
    if (source === RoomBookingSource.TEAM_TODAY) {
      // Team today booking, room not linked to Outlook
      return Colours.blue;
    } else if (source === RoomBookingSource.OUTLOOK) {
      // Outlook booking, room linked to Outlook but no booking found in Team Today
      return Colours.outlookBlue;
    } else if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
      // In system and matched to outlook, but shouldn't get here
      return Colours.green;
    }
  }

  return Colours.darkGrey;
}

function getDebugMessage(status: MeetingRoomStatus, source: RoomBookingSource): any {
  if (status === MeetingRoomStatus.PENDING) {
    if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
      return (<>
        <BodySmall weight={600}>Outlook booking</BodySmall>
        <ul>
          <li><BodySmall>In users calendar</BodySmall></li>
          <li><BodySmall>NOT in meeting room calendar</BodySmall></li>
        </ul>
        <BodySmall>This booking can be managed by the user but it may not yet be visible to other users.</BodySmall>
      </>);
    }
  }

  if (status === MeetingRoomStatus.COMPLETE) {
    if (source === RoomBookingSource.TEAM_TODAY) {
      return (<>
        <BodySmall weight={600}>Team today booking</BodySmall>
        <BodySmall>Room not linked to Outlook</BodySmall>
      </>);
    } else if (source === RoomBookingSource.OUTLOOK) {
      return (<>
        <BodySmall weight={600}>Outlook booking</BodySmall>
        <ul>
          <li><BodySmall>NOT in users calendar</BodySmall></li>
          <li><BodySmall>In meeting room calendar</BodySmall></li>
        </ul>
        <BodySmall>This implies the room is busy and this user has not made the booking.</BodySmall>
      </>);
    } else if (source === RoomBookingSource.SYSTEM_LINKED_TO_OUTLOOK) {
      return (<>
        <BodySmall weight={600}>Outlook booking</BodySmall>
        <ul>
          <li><BodySmall>In users calendar</BodySmall></li>
          <li><BodySmall>In meeting room calendar</BodySmall></li>
        </ul>
        <BodySmall>Can be managed by the user</BodySmall>
      </>);
    }
  }

  return (<>
    <BodySmall>{`Nothing has matched. Status: ${status}. Source: ${source}`}</BodySmall>
  </>);
}

const Container = styled.div<any>`
  position: absolute;
  top: ${props => props.hourOffset + props.minuteOffset}px;
  left: 2%;
  height: ${props => props.bookingHeight}px;
  width: 96%;
  background-color: ${props => getFillColour(props.status, props.source)};
  border-radius: 4px;
  border: 1px solid #064CD2;
  border-color: ${props => getOutlineColour(props.status, props.source)};
  padding: 6px 16px;
  .meetingIcon {
    position: absolute;
    bottom: 12px;
    right: 12px;
  }
  .meeting__bookerName {
    max-width: 85%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 16px;
  }
  &:hover {
    .meetingBooking__debugBox {
      display: block !important;
    }
  }
`

const ContentWrapper = styled.div<any>`
  height: ${props => props.bookingHeight}px;
  overflow: hidden;
  &:hover {
    .meetingBooking__debugBox {
      display: block !important;
    }
  }
`

const DebugBox = styled.div`
  display: none;
  background-color: white;
  border-radius: 12px;
  min-height: 100px;
  min-width: 200px;
  max-width: 300px;
  position: absolute;
  padding: 12px;
  top: 10px;
  left: 99%;
  z-index: 99999999;
  ul {
    margin-left: 24px;
  }
`
