import React, { useMemo } from 'react';
import Icon, { IconTypes } from "../atoms/icon/Icon";
import { BodyXXSmall } from "../atoms/fonts/Body";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from "react-redux";
import { DialogIdentifiers, openDialogWithPayload } from "../../../store/ducks/dialog.duck";
import { selectCalendarDate } from "../../../store/ducks/dashboard.duck";
import { selectFollowingTeams } from "../../../store/ducks/followingTeamsAndTags.duck";
import { selectCurrentUser } from "../../../store/ducks/auth.duck";
import { warningNotification } from "../../../store/ducks/notification.duck";
import { useTranslation } from "react-i18next";

interface Props {
}

export function WeekNotesRowLink(props: Props) {
  const dispatch = useDispatch();
  const calendarDate = useSelector(selectCalendarDate);
  const followingTeams = useSelector(selectFollowingTeams);
  const currentUser = useSelector(selectCurrentUser);
  const {t} = useTranslation();
  const teamsToOpen = useMemo(() => {
    if (followingTeams.length > 0) {
      return followingTeams.map(t => t.id);
    } else if (currentUser?.teamEntity) {
      return [currentUser.teamEntity];
    } else {
      return [];
    }
  }, [followingTeams, currentUser])


  const onClick = () => {
    if (followingTeams.length === 0) {
      dispatch(warningNotification('No teams are selected'))
    } else {
      dispatch(openDialogWithPayload({
        payload: { date: calendarDate, teamIds: teamsToOpen },
        activeDialog: DialogIdentifiers.WeeklyNotes
      }))
    }
  }

  return <SpaceBetweenRow onClick={onClick}>
    <Icon size={'mediumlarge'} icon={IconTypes.Notification} />
    <BodyXXSmall weight={600}>{t('week-notes.week-notes')}</BodyXXSmall>
  </SpaceBetweenRow>
}


const SpaceBetweenRow = styled.div` 
  display: flex;
  align-items: center;
  cursor: pointer;
  .icon {
    margin-right: 16px;
  }
`
