import React, { useState } from 'react';
import { SecondaryButton } from "../atoms/buttons/SecondaryButton";
import { acceptAndSyncMovements } from "../../../services/Movements";
import moment, { Moment } from "moment";
import { refreshAllMovements } from "../../../store/ducks/companyMovements.duck";
import { useDispatch } from "react-redux";

export function ResyncCalendarButton(props: Props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const acceptAndSync = async () => {
    setIsLoading(true);
    try {
      await acceptAndSyncMovements(moment(props.date));
      dispatch(refreshAllMovements());
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <SecondaryButton className="wallchartRow__acceptBtn" click={acceptAndSync} text="Accept changes" disabled={isLoading} />
  )
}

interface Props {
  date: Moment;
}
